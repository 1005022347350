<div class="usuario-interno-edicao">
    <div class="card col-md-8 offset-md-2 mb0">
        <form novalidate (ngSubmit)="save()" [formGroup]="cadastroForm">
            @if(!showAssociations) {
                <div class="d-flex">
                    <div class="col-md-6">
                        <div class="mb-3 form-group">
                            <label for="name">{{'support.manutencao.usuario.interno.edicao.campos.nome.label' | translate}}</label>
                            <input type="text" class="form-control text-capitalize" id="name" formControlName="name" [ngClass]="{'is-invalid': displayMessage.name }" maxlength="50" />
                            @if (displayMessage.name) {
                                <span class="text-danger">
                                    <p [innerHTML]="displayMessage.name"></p>
                                </span>
                            }
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3 form-group">
                            <label for="email">{{'support.manutencao.usuario.interno.edicao.campos.email.label' | translate}}</label>
                            <input type="email" class="form-control text-lowercase" id="email" formControlName="email" [ngClass]="{'is-invalid': displayMessage.email }" maxlength="50" />
                            @if (displayMessage.email) {
                                <span class="text-danger">
                                    <p [innerHTML]="displayMessage.email"></p>
                                </span>
                            }
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="col-md-6">
                        <div class="mb-3 form-group">
                            <label for="username">{{'support.manutencao.usuario.interno.edicao.campos.login.label' | translate}}</label>
                            <input type="text" class="form-control text-lowercase" id="username" formControlName="username" [ngClass]="{'is-invalid': displayMessage.username }" maxlength="20" />
                            @if (displayMessage.username) {
                                <span class="text-danger">
                                    <p [innerHTML]="displayMessage.username"></p>
                                </span>
                            }
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3 form-group">
                            <label for="password">{{'support.manutencao.usuario.interno.edicao.campos.senha.label' | translate}}</label>
                            <div class="input-group">
                                <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="password" formControlName="password" [ngClass]="{'is-invalid': displayMessage.password }" maxlength="20" />
                                <span class="input-group-text cursor-pointer" (click)="this.showPassword = !this.showPassword"><i class="fa" [ngClass]="{ 'fa-eye': !showPassword, 'fa-eye-slash': showPassword }" aria-hidden="true"></i></span>
                            </div>
                            @if (displayMessage.password) {
                                <span class="text-danger">
                                    <p [innerHTML]="displayMessage.password"></p>
                                </span>
                            }
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="col-md-6">
                        <div class="mb-3 form-group">
                            <label for="whatsApp">{{'support.manutencao.usuario.interno.edicao.campos.whatsapp.label' | translate}}</label>
                            <input type="text" class="form-control" id="whatsApp" formControlName="whatsApp" [ngClass]="{'is-invalid': displayMessage.whatsApp }" cellphone [mask]="MASKS.cellphone.text" />
                            @if (displayMessage.whatsApp) {
                                <span class="text-danger">
                                    <p [innerHTML]="displayMessage.whatsApp"></p>
                                </span>
                            }
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3 form-group">
                            <label for="role">{{'support.manutencao.usuario.interno.edicao.campos.perfil.label' | translate}}</label>
                            <select class="form-select" formControlName="role">
                                <option [value]="roles.Central">{{'support.manutencao.usuario.interno.edicao.campos.perfil.central' | translate}}</option>
                                <option [value]="roles.CentralSuporte">{{'support.manutencao.usuario.interno.edicao.campos.perfil.central-suporte' | translate}}</option>
                                <option [value]="roles.Faturamento">{{'support.manutencao.usuario.interno.edicao.campos.perfil.faturamento' | translate}}</option>
                                <option [value]="roles.Suporte">{{'support.manutencao.usuario.interno.edicao.campos.perfil.suporte' | translate}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                @if (user.userId > 0) {
                    <div class="d-flex mb10">
                        <div class="c-checkbox needsclick checkboxcot col-md-3">
                            <label class="needsclick">
                                <input type="checkbox" class="needsclick" id="ativo" formControlName="isActive" />
                                <span class="fa fa-check"></span> {{'support.manutencao.usuario.interno.edicao.campos.ativo.label' | translate}}
                            </label>
                        </div>
                    </div>
                }
            }
            @else {
                <div class="col-md-12 perfil-central mt10">
                    <fieldset>
                        <div class="oficinas col-md-8 offset-md-2">                                
                            <section class="lista-compradores d-flex flex-wrap striped">
                                <div class="search-section">
                                    <div class="input-group">
                                        <span class="input-group-text"><i class="fa fa-search"></i></span>
                                            <input maxlength="100" placeholder="{{'support.manutencao.usuario.interno.edicao.campos.perfil.filtro' | translate}}" class="form-control" (input)="onInputChange($event)" />
                                    </div>
                                </div>
                                <header class="linha">
                                    <div class="col">{{'support.manutencao.usuario.interno.edicao.perfil-central.compradores.cabecalho.oficina' | translate}}</div>
                                    <div class="col">{{'support.manutencao.usuario.interno.edicao.perfil-central.compradores.cabecalho.comprador' | translate}}</div>
                                    <div class="col-0-5 text-center">
                                        <div class="form-check c-checkbox needsclick checkboxcot">
                                            <label class="needsclick">
                                                <input type="checkbox" class="needsclick" (click)="selectAllRepairers($event);">
                                                <span class="fa fa-check"></span>
                                            </label>
                                        </div>
                                    </div>
                                </header>
                                <ng-scrollbar height="auto" class="corpo">
                                    @for (repairer of repairersFiltered; track repairer) {
                                        <div class="linha">
                                            <div class="col">{{repairer.repairerName}}</div>
                                            <div class="col">{{repairer.buyerName}}</div>
                                            <div class="col-0-5 text-center">
                                                <div class="form-check c-checkbox needsclick checkboxcot">
                                                    <label class="needsclick">
                                                        <input type="checkbox" class="needsclick" (click)="selectRepairer(repairer, $event)" [checked]="repairer.selected" />
                                                        <span class="fa fa-check"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    @if (!repairersFiltered || repairersFiltered.length === 0) {
                                        <div class="text-center vazio">
                                            <p class="mb0">{{'support.manutencao.usuario.interno.lista.mensagens.lista-oficinas-vazia' | translate}}</p>
                                        </div>
                                    }
                                </ng-scrollbar>
                            </section>
                        </div>
                    </fieldset>
                </div>
            }
            <div class="col-md-12 text-center actions">
                <button type="submit" [disabled]="!this.cadastroForm.valid || isSaveButtonDisabled ? 'disabled' : null"
                    class="btn btn-primary">{{'elemento.generico-botao.salvar' | translate}}</button>
                &nbsp;
                <input type="button" class="btn btn-light" value="{{'elemento.generico-botao.voltar' | translate}}" (click)="back()" />
            </div>
        </form>  
    </div>
</div>